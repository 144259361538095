var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("input", {
    style: _vm.style,
    attrs: {
      type: _vm.type,
      placeholder: _vm.placeholder,
      maxlength: _vm.maxLength,
    },
    domProps: { value: _vm.text },
    on: { input: _vm.changeText },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }